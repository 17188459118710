import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Card,
  Stack,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import CardContent from "@mui/material/CardContent";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useParams } from "react-router";
import { useMediaQuery, useTheme } from "@mui/material/";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AddTransaction from "../../AddTransaction/AddTransaction.tsx";
import {
  getAllTransactions,
  getAllCustomers,
  getCustomerBy,
  deleteTransaction,
} from "../../../service/api.service";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import InfiniteList from "../../../common/InfiniteList.jsx";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomModal from "../../../common/Modal/CustomModal.jsx";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

let limit = 10;
const cellWidth = 300;

const CustomerData = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { custID } = useParams();
  const [customer, setCustomer] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleteFocused, setIsDeleteFocused] = useState(false);
  const [txnToDelete, setTxnToDelete] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const fetchData = async (page) => {
    try {
      setLoading(true);
      const response = await getAllTransactions({
        customerId: custID,
        page,
        limit: 10,
      });
      if (!totalPages) {
        setTotalPages(Math.ceil(response?.transactions?.totalCount / 10));
      }
      let txns = response?.transactions?.transactions;
      if (txns && txns?.length && response?.transactions?.page === page) {
        let prevTxns = rows;
        prevTxns.splice((page - 1) * limit, txns?.length, ...txns);
        // console.log("prevTxns2", prevTxns);
        setRows(prevTxns?.map((d) => d));
      } else setRows([]);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataName = async () => {
    try {
      const response = await getCustomerBy({ customerId: custID });
      if (response) {
        setCustomer({
          ...response,
          accountNumber: response?.accounts[0]?.accountNumber || "",
        });
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  useEffect(() => {
    fetchData(1);
    fetchDataName();
  }, [custID]);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, custID]);

  useEffect(() => {
    if (refresh) {
      setCurrentPage(1);
      fetchData(1);
      setRefresh(false);
    }
  }, [refresh, custID]);

  const handleDeleteTransaction = (index, txnID) => {
    deleteTransaction(txnID).then((res) => {
      if (res?.data) {
        let data = rows;
        data.splice(index, 1);
        setRows(data.map((d) => d));
        setRefresh(true);
      }
    });
  };

  const CustomRow = ({ index, style }) => {
    const historyRow = rows?.[index];
    return (
      <TableRow component="div" style={style} key={historyRow?._id}>
        <TableCell component="div" align="left" style={{ width: cellWidth }}>
          {new Date(historyRow?.date).toLocaleString() ?? "-"}
        </TableCell>
        <TableCell
          component="div"
          align={"right"}
          style={{
            width: cellWidth,
            color: historyRow?.type === "credit" ? "green" : "red",
          }}
        >
          {`${historyRow?.type === "credit" ? "+" : "-"}${historyRow?.amount}`}
        </TableCell>
        <TableCell component="div" align="center" style={{ width: cellWidth }}>
          {historyRow?.type ?? "-"}
        </TableCell>
        <TableCell
          component="div"
          align="right"
          style={{
            width: cellWidth,
            fontWeight: index === 0 ? "bold" : "normal",
          }}
        >
          {historyRow?.totalAmount ?? "-"}
        </TableCell>
        <TableCell component="div" align="center" style={{ width: cellWidth }}>
          {
            <IconButton
              onClick={() => {
                setTxnToDelete({ index, txnID: historyRow?._id });
                setShowPopup(true);
              }}
              onMouseEnter={setIsDeleteFocused(true)}
              onMouseLeave={setIsDeleteFocused(false)}
            >
              <DeleteIcon
                sx={{
                  color: isDeleteFocused
                    ? theme?.palette?.secondary?.main
                    : "primary",
                }}
              />
            </IconButton>
          }
        </TableCell>
      </TableRow>
    );
  };

  const loadMoreItems = ({ visibleStopIndex }) => {
    if (visibleStopIndex + 10 >= rows.length && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <Container
      sx={{
        marginTop: isSmallScreen ? "1rem" : "1rem",
        maxHeight: "80vh",
      }}
    >
      <Card>
        <CardContent>
          <Stack direction="row">
            <Stack direction="column" flexGrow={1}>
              <Typography variant="h5" component="div">
                {`${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {`${customer?.accountNumber}`}
              </Typography>
            </Stack>
            <Stack direction="column" flexGrow={1}>
              <Typography variant="h5" component="div">
                {`Total Amount`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {`${rows?.[0]?.totalAmount ?? 0}`}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <div style={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h6" id="tableTitle">
            Transactions History
          </Typography>
          <AddTransaction
            setRefresh={setRefresh}
            customer={{
              customerId: customer?._id,
              name: `${customer?.firstName} ${customer?.lastName ?? ""}`,
              account: customer?.accountNumber,
            }}
          />
        </Stack>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: cellWidth }}>Date</TableCell>
                  <TableCell style={{ width: cellWidth }} align="center">
                    Amount
                  </TableCell>
                  <TableCell style={{ width: cellWidth }} align="center">
                    Transaction Type
                  </TableCell>
                  <TableCell style={{ width: cellWidth }} align="center">
                    Total Amount (INR)
                  </TableCell>
                  <TableCell
                    style={{ width: cellWidth }}
                    align="center"
                  ></TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : null}
                <InfiniteList
                  data={rows}
                  height={250}
                  alignItems="center"
                  width={"100%"}
                  overscanCount={1}
                  loadMoreItems={loadMoreItems}
                >
                  {CustomRow}
                </InfiniteList>
              </TableBody>
            </Table>
          </TableContainer>
        </LocalizationProvider>
      </div>
      {showPopup ? (
        <CustomModal
          open={showPopup}
          title={`Do you want to remove the transaction of ${
            rows[txnToDelete?.index]?.amount ?? ""
          }?`}
          width={"xs"}
          handleClose={() => {
            setTxnToDelete({});
            setShowPopup(false);
          }}
          actions={
            <Grid
              style={{
                width: "100%",
                marginTop: "2%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              xs={12}
              sm={6}
            >
              <Button
                style={{ marginRight: "16px" }}
                fullWidth
                variant="outlined"
                endIcon={<CheckIcon />}
                onClick={() => {
                  handleDeleteTransaction(
                    txnToDelete?.index,
                    txnToDelete?.txnID
                  );
                  setShowPopup(false);
                }}
              >
                Yes
              </Button>
              <Button
                fullWidth
                variant="contained"
                endIcon={<CancelIcon />}
                onClick={() => {
                  setShowPopup(false);
                  setTxnToDelete({});
                }}
              >
                No
              </Button>
            </Grid>
          }
        ></CustomModal>
      ) : null}
    </Container>
  );
};

export default CustomerData;
