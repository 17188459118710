import React from 'react'
import "./Announcement.css";
import Marquee from "react-fast-marquee";

function Announcements() {
  return (
    <div className="announcement">
        <Marquee speed={40}>
        <div className="announcement-text text1">
            <span>Naitra Social Welfare Foundation | Financial Aid Society, 325, Bus Stand, Kalmukhi.</span>
            <span></span>
        </div>
        <div className="announcement-text text2">
        <span>Our Services : Jan-Dhan Jama Yojna | Gold Loan | F.D. Yojna | R.D. Yojna | Mortgage Loan | Loan Against Property etc..</span>
        </div>
        </Marquee>
    </div>
  )
}

export default Announcements