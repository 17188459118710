// Import necessary dependencies
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import { createCustomer, getAllService } from "../../service/api.service";
import CustomModal from "../../common/Modal/CustomModal";

const style = {
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function filterObject(obj) {
  const result = {};
  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== "") {
      result[key] = obj[key];
    }
  }
  return result;
}

export default function AddCustomer(props: any) {
  const { setRefresh } = props;
  const [open, setOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    relativeName: "",
    nominee: "",
    occupation: "",
    address: "",
    aadhar: "",
    pan: "",
    serviceId: "",
    serviceType: "",
    date: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = React.useState<Dayjs | null>(dayjs(null));
  const [tvalue, setTValue] = React.useState<Dayjs | null>(dayjs());

  const [isServiceOpen, setIsServiceOpen] = React.useState(false);
  const [services, setServices] = useState<any>([]);
  const serviceLoading = isServiceOpen && services.length === 0;

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateCustomer = async () => {
    try {
      const response = await createCustomer(
        filterObject({ ...formData, date: tvalue, services: selectedServices })
      );
      console.log("Customer created successfully:", response);

      setSnackbarSeverity("success");
      setSnackbarMessage("Customer Added Successfully!");
      setSnackbarOpen(true);
      setRefresh(true);
      handleClose();
    } catch (error) {
      console.error("Error creating customer:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Something went wrong. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const [selectedServices, setSelectedServices] = useState([]);

  const handleService = (e, newValue) => {
    setSelectedServices(newValue?.map(({id}) => id));
    console.log("newValue", newValue, selectedServices);
  };

  React.useEffect(() => {
    let active = true;

    if (!serviceLoading) {
      return undefined;
    }

    if (active) {
      getAllService({}).then((res) =>
        setServices(
          res?.services?.services?.map(({ name, _id }) => ({ name, id: _id }))
        )
      );
    }

    return () => {
      active = false;
    };
  }, [serviceLoading]);

  return (
    <>
      <Button onClick={handleOpen} sx={{ marginTop: 2, marginLeft: 2 }}>
        Add Customer
      </Button>
      <CustomModal
        open={open}
        title={"Register Customer"}
        handleClose={handleClose}
        actions={
          <Grid
            style={{
              marginTop: "2%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            xs={12}
            sm={6}
          >
            <Button
              style={{ marginRight: "16px" }}
              fullWidth
              variant="outlined"
              endIcon={<CancelIcon />}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              endIcon={<SendIcon />}
              onClick={handleCreateCustomer}
            >
              Submit
            </Button>
          </Grid>
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Stack direction="row" spacing={1}>
              <Chip label="Account Number will be auto generate" />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date"
                    value={tvalue}
                    onChange={(newTValue) => setTValue(newTValue)}
                    format="LL"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              required
              id="firstName"
              name="firstName"
              label="First Name"
              fullWidth
              variant="outlined"
              value={formData.firstName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              id="lastname"
              name="lastName"
              label="Last Name"
              fullWidth
              variant="outlined"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              id="email"
              name="email"
              label="Email"
              fullWidth
              variant="outlined"
              value={formData.email}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              required
              id="relativeName"
              name="relativeName"
              label="Father / Spouse Name"
              fullWidth
              variant="outlined"
              value={formData.relativeName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              // required
              id="nominee"
              name="nominee"
              label="Nominee"
              fullWidth
              variant="outlined"
              value={formData.nominee}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              // required
              id="occupation"
              name="occupation"
              label="Occupation"
              fullWidth
              variant="outlined"
              value={formData.occupation}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              // required
              size="small"
              id="address"
              name="address"
              label="Address"
              fullWidth
              variant="outlined"
              value={formData.address}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              required
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              fullWidth
              variant="outlined"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              // required
              id="aadhar"
              name="aadhar"
              label="AADHAR"
              fullWidth
              variant="outlined"
              value={formData.aadhar}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              // required
              id="pan"
              name="pan"
              label="PAN"
              fullWidth
              variant="outlined"
              value={formData.pan}
              onChange={handleInputChange}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                options={servicesList}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Select Service"
                    placeholder="add..."
                    name="serviceId"
                    value={formData.serviceId}
                    onChange={handleInputChange}
                  />
                )}
              />
            </Grid> */}
          <Grid item xs={12} sm={12}>
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={services}
              open={isServiceOpen}
              onOpen={() => {
                setIsServiceOpen(true);
              }}
              onClose={() => {
                setIsServiceOpen(false);
              }}
              onChange={handleService}
              loading={serviceLoading}
              isOptionEqualToValue={(option, value) =>
                option?._id === value?._id
              }
              getOptionLabel={(option) => option?.name ?? ""}
              renderOption={(props, option) => (
                <span {...props}>{option?.name}</span>
              )}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Services"
                  placeholder="add..."
                  name="service"
                />
              )}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
