import * as React from 'react';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'; 
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

export default function RunningAmountView() {
  const handleEdit = () => {
    // Add your logic to handle the edit view here
    console.log('Edit View Clicked!');
  };

  const content = (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {/* Make the "Total Running Amount" text bold */}
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        Total Running Amount : INR Loading...
      </Typography>
      <Button onClick={handleEdit} sx={{ marginLeft: '1rem' }}>
        <FilterAltIcon />
      </Button>
    </Container>
  );

  return (
    <Container>
      <Root>
        {content}
        <Divider />
      </Root>
    </Container>
  );
}
