import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccordionActions from "@mui/material/AccordionActions";
import { Container } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  getCustomerServices,
  getAllTransactions,
  getAllCustomers,
  getCustomerBy,
} from "../../../service/api.service";
import CustomModal from "../../../common/Modal/CustomModal";

const servicesList = [{ title: "Loan" }, { title: "Saving" }];

const style = {
  margin: "5rem 0rem 5rem 0rem",
};

export default function CustomersList() {
  const [expanded, setExpanded] = React.useState(true);
  const [editing, setEditing] = React.useState(false);
  const [fullName, setFullName] = React.useState("");
  const [policyName, setPolicy] = React.useState("");
  const { custID } = useParams();
  const [customer, setCustomer] = useState({});
  const [service, setService] = useState({});

  const fetchDataName = async () => {
    try {
      const response = await getCustomerBy({ customerId: custID });
      if (response) {
        setCustomer({
          ...response,
          accountNumber: response?.accounts[0]?.accountNumber || "",
        });
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await getCustomerServices({ customerId: custID });
      if (response) {
        setService({
          ...response,
          serviceType: response?.serviceType || "",
        });
      }
    } catch (error) {
      console.error("Error fetching service id:", error);
    }
  };

  useEffect(() => {
    fetchDataName();
    fetchServices();
  }, [custID]);

  const handleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = () => {
    setEditing(false);
    // Add logic to save the updated data (e.g., API call or local state update)
  };

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handlePolicyChange = (event) => {
    setPolicy(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = React.useState<Dayjs | null>(dayjs(null));
  const [tvalue, setTValue] = React.useState<Dayjs | null>(dayjs());

  const formatDate = (dateString) => {
    if (!dateString) return "";
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB').format(date); // 'en-GB' locale formats date as DD/MM/YYYY
  };

  return (
    <React.Fragment>
      <Container>
        <Box sx={style}>
          <Card>
            <Accordion
              expanded={expanded}
              //  onChange={handleExpand}
            >
              <AccordionSummary
                expandIcon={<VerifiedUserIcon style={{transform: "rotate(180deg)", color: "#4aa92c"}} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Account Number: {`${customer?.accountNumber}`}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {`${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {/* {`${service?.serviceType}`} */}
                  </Typography>
                </CardContent>
              </AccordionSummary>
              <AccordionDetails>
                <CardContent>
                  <Box>
                    <Grid style={{ margin: "0rem 0rem 1.5rem 0rem" }}>
                      <Typography variant="h6" gutterBottom>
                        Full Details
                      </Typography>
                      <Grid item xs={12} sm={6}>
                      <TextField
                          id="date"
                          name="date"
                          label="Date"
                          variant="outlined"
                          size="small"
                          value={formatDate(customer?.date ?? "")}
                          disabled={!editing}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="firstName"
                          name="firstName"
                          label="First Name"
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={`${customer?.firstName ?? ""}`}
                          onChange={handleFullNameChange}
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="lastName"
                          name="lastName"
                          label="Last Name"
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={`${customer?.lastName ?? ""}`}
                          onChange={handleFullNameChange}
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="email"
                          name="email"
                          label="Email"
                          size="small"
                          value={`${customer?.email ?? "N/A"}`}
                          fullWidth
                          variant="outlined"
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="father_name_or_spouse_name"
                          name="father_name_or_spouse_name"
                          label="Father / Spouse Name"
                          size="small"
                          value={`${customer?.relativeName ?? "N/A"}`}
                          fullWidth
                          variant="outlined"
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="nominee"
                          name="nominee"
                          label="Nominee"
                          size="small"
                          value={`${customer?.nominee ?? "N/A"}`}
                          fullWidth
                          variant="outlined"
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="occupation"
                          name="occupation"
                          label="Occupation"
                          value={`${customer?.occupation ?? "N/A"}`}
                          fullWidth
                          variant="outlined"
                          size="small"
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="address"
                          name="address"
                          label="Address"
                          value={`${customer?.address ?? "N/A"}`}
                          size="small"
                          fullWidth
                          variant="outlined"
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="phoneNumber"
                          name="phoneNumber"
                          label="Phone Number"
                          value={`${customer?.phoneNumber ?? "N/A"}`}
                          size="small"
                          fullWidth
                          variant="outlined"
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="aadhar"
                          name="aadhar"
                          label="AADHAR"
                          value={`${customer?.aadhar ?? "N/A"}`}
                          size="small"
                          fullWidth
                          variant="outlined"
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="pan"
                          name="pan"
                          label="PAN"
                          value={`${customer?.pan ?? "N/A"}`}
                          size="small"
                          fullWidth
                          variant="outlined"
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="serviceId"
                          name="serviceId"
                          label="Service Name"
                          size="small"
                          value={`${customer?.serviceId ?? "N/A"}`}
                          fullWidth
                          variant="outlined"
                          onChange={handlePolicyChange}
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          multiple
                          id="size-small-outlined-multi"
                          size="small"
                          options={servicesList}
                          getOptionLabel={(option) => option.title}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="serviceType"
                              label="Services Type"
                              placeholder="add..."
                              disabled={!editing}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </AccordionDetails>
              <AccordionActions>
                <Button onClick={handleClose}>Cancel</Button>
                {editing ? (
                  <Button onClick={handleSave}>SAVE</Button>
                ) : (
                  <Button onClick={handleEdit}>EDIT</Button>
                )}
              </AccordionActions>
            </Accordion>
          </Card>
        </Box>
      </Container>
    </React.Fragment>
  );
}
