import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Grid, Typography, Button, Collapse } from '@mui/material';
import { getAllService } from '../../../service/api.service';

const ServiceCard = ({ service }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <CardHeader title={service.name} subheader={service.type} />
      <CardContent>
        <Typography variant="body2" color="textSecondary">
          Amount: {service.amount}
        </Typography>
        <Button onClick={handleExpandClick} size="small" style={{ marginBottom: '10px' }}>
          {expanded ? 'Hide Details' : 'Show Details'}
        </Button>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {service.type === 'savings' && (
            <>
              <Typography variant="body2" color="textSecondary">
                Target Amount: {service.saving?.targetAmount}
              </Typography>
            </>
          )}
          {service.type === 'loan' && (
            <>
              <Typography variant="body2" color="textSecondary">
                Loan Amount: {service.loan?.loanAmount}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Interest Rate: {service.loan?.interestRate}%
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Duration: {service.loan?.duration} months
              </Typography>
            </>
          )}
          <Typography variant="body2" color="textSecondary">
            Collection Frequency: {service.collectionFrequency}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Description: {service.description}
          </Typography>
        </Collapse>
      </CardContent>
    </Card>
  );
};

const ServicesPage = () => {
  const [services, setServices] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  useEffect(() => {
    getAllService({page, limit}).then(res => setServices(res?.services?.services))
  }, [])

  return (
    <Grid container spacing={3}>
      {services?.map((service, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
          <ServiceCard service={service} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ServicesPage;
