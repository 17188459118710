import * as React from "react";
import { useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Container, Button, Snackbar, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteCustomerById,
  getAllCustomers,
  getAllService,
  getCustomerServices,
} from "../../../service/api.service";
import MuiAlert from "@mui/material/Alert";
import CustomModal from "../../../common/Modal/CustomModal";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

export default function DataTable(props) {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(null);
  const [limit, setLimit] = useState(10);
  const { search, refresh, setRefresh } = props;
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();
  const [services, setServices] = useState({});

  React.useEffect(() => {
    if (rows) {
      for (let customer of rows) {
        if (!services?.[customer?._id])
          getCustomerServices({
            customerId: customer?._id,
            isActive: true,
            page: 1,
            limit: 10,
          }).then((res) =>
            setServices((prev) => ({
              ...prev,
              [customer?._id]: res?.services?.services?.map((s) => ({
                name: s?.service?.name,
                id: s?.service?._id,
              })),
            }))
          );
      }
    }
  }, [rows]);

  const columns: GridColDef[] = [
    // { field: "id", headerName: "SR. No.", width: 70 },
    {
      field: "fullName",
      renderCell: (cellValue) =>
        `${cellValue?.row?.firstName ?? ""} ${cellValue?.row?.lastName ?? ""}`,
      headerName: "Full name",
      width: 170,
    },
    {
      field: "accounts",
      valueGetter: (cellValue) => cellValue?.row?.accounts?.[0]?.accountNumber,
      headerName: "Account Number",
      width: 170,
    },
    {
      field: "serviceName",
      valueGetter: (cellValue) =>
        services?.[cellValue?.row?._id]?.map((s) => s.name)?.join(", ") ?? "",
      headerName: "Service Name",
      width: 150,
    },
    {
      field: "lastCreditAmount",
      headerName: "Last Deposited Amount",
      width: 180,
    },
    {
      field: "totalAmount",
      // valueGetter: (cellValue) => cellValue?.row?.totalAmount,
      headerName: "Total Collected Amount",
      width: 180,
    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      valueGetter: (cellValue) =>
        new Date(cellValue?.value).toLocaleDateString(),
    },
    {
      field: "linkHistory",
      headerName: "Customer History",
      width: 140,
      renderCell: (params) => (
        <Button
          size="small"
          color="primary"
          onClick={() =>
            navigate(`/cdata/${params.id}`, {
              state: {
                customer: params?.row,
              },
            })
          }
        >
          View History
        </Button>
      ),
    },
    {
      field: "linkProfile",
      headerName: "Customer Profile",
      width: 140,
      renderCell: (params) => (
        <Button
          size="small"
          color="primary"
          onClick={() =>
            navigate(`/clist/${params.id}`, {
              state: {
                customer: params?.row,
              },
            })
          }
        >
          View Profile
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "",
      width: 140,
      renderCell: (params) => (
        <Button
          size="small"
          color="primary"
          onClick={() => {
            setCustomerToDelete(params.row);
            setShowConfirmation(true);
          }}
        >
          Remove
        </Button>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      const response = await getAllCustomers({
        search,
        page: page + 1,
        limit: limit,
      });
      setRows(response?.customers);
      setTotalRows(response?.totalCount);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [search, page, limit]);

  React.useEffect(() => {
    if (refresh) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  const handleDelete = (id) => {
    deleteCustomerById({ customerId: id })
      .then((res) => {
        setSnackbarSeverity("success");
        setSnackbarMessage("Customer Removed Successfully!");
        setSnackbarOpen(true);
        setRefresh(true);
      })
      .catch((err) => {
        setSnackbarSeverity("error");
        setSnackbarMessage("Error while removing Customer!");
        setSnackbarOpen(true);
      });
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  return (
    <Container sx={{ justifyContent: "center" }} maxWidth={"xl"}>
      <div style={{ width: "100%", justifyContent: "center" }}>
        <DataGrid
          style={{ padding: "1rem", justifyItems: "center", height: "400px" }}
          rows={rows}
          rowCount={totalRows}
          columns={columns}
          getRowId={(row) => row._id}
          paginationMode="server"
          pageSizeOptions={[10, 30, 50, 100]}
          paginationModel={{ page: page, pageSize: limit }}
          onPaginationModelChange={(e) => {
            setPage(e?.page);
            setLimit(e?.pageSize);
          }}
        />
      </div>
      {showConfirmation ? (
        <CustomModal
          open={showConfirmation}
          title={`Do you want to remove ${customerToDelete?.firstName ?? ""} ${
            customerToDelete?.lastName ?? ""
          }`}
          width={"xs"}
          handleClose={() => {
            setCustomerToDelete(null);
            setShowConfirmation(false);
          }}
          actions={
            <Grid
              style={{
                width: "100%",
                marginTop: "2%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              xs={12}
              sm={6}
            >
              <Button
                style={{ marginRight: "16px" }}
                fullWidth
                variant="outlined"
                endIcon={<CheckIcon />}
                onClick={() => {
                  handleDelete(customerToDelete?._id);
                  setShowConfirmation(false);
                }}
              >
                Yes
              </Button>
              <Button
                fullWidth
                variant="contained"
                endIcon={<CancelIcon />}
                onClick={() => {
                  setShowConfirmation(false);
                  setCustomerToDelete(null);
                }}
              >
                No
              </Button>
            </Grid>
          }
        ></CustomModal>
      ) : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
}
