import MainAppBar from "../AppBar/AppBar.tsx";
import React from "react";
// import "./App.css";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Login from "../Pages/Auth/Login.tsx"
import Signup from "../Pages/Auth/Signup.tsx"
import CustomersList from "../Pages/CustomersList/CustomersList.tsx";
import Home from "../Pages/Home.tsx";
import CustomerData from "../Pages/CustomersList/CustomerData.tsx";
import DataTable from "../Pages/DataTable/DataTable.tsx";
import TempDrawer from "../TempDrawer/TempDrawer.tsx";
import Services from "../Pages/Services/Services.tsx";
import CreateCustomer from "../Customer/CreateCustomer.js";
import AppBar from "../AppBar/AppBar.tsx";
import { Container, ThemeProvider } from "@mui/material";
import { theme } from "../../theme/index.js";
// import Customers from "../Pages/CustomersList/Customers.tsx";
import Announcements from "../Announcements/Announcements.tsx";

const PublicRoute = ({ element, ...props }) => {
  const isAuthenticated = localStorage.getItem('token') ? true : false;
  return !isAuthenticated ? (
    <Outlet {...props} element={element} />
  ) : (
    <Navigate to="/" replace />
  );
};

const PrivateRoute = ({ element, ...props }) => {
  const isAuthenticated = localStorage.getItem('token') ? true : false;

  return isAuthenticated ? (
    <Outlet {...props} element={element} />
  ) : (
    <Navigate to="/login" replace />
  );
};

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={"/"}>
          <AppBar />
          <Container sx={{marginTop: "6%"}}>
            {/* <TempDrawer /> */}
            <Routes>
              <Route path="/" element={<PrivateRoute />} >
                <Route index element={<Home />} />
                <Route exact path="clist/:custID" element={<CustomersList />} />
                <Route exact path="cdata/:custID" element={<CustomerData />} />
                <Route exact path="data" element={<DataTable />} />
                <Route exact path="services" element={<Services />} />
                <Route exact path="create-customer" element={<CreateCustomer />} />
              </Route>
              <Route path="/" element={<PublicRoute />}>
                <Route path={'login'} element={<Login />} />
                <Route path={'signup'} element={<Signup />} />
              </Route>
            </Routes>
          </Container>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
