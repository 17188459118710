import React, { useState } from "react";
import SearchBar from "../Search/SearchBar.tsx";
import RunningAmountView from "../RunningAmountView/RunningAmountView.tsx";
import PolicyCard from "../PolicyCard/PolicyCard.tsx";
import DataTable from "./DataTable/DataTable.tsx";
import { Container } from "@mui/material";


function Home() {
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
    return (
      <div>
        <SearchBar setSearch={setSearchValue} setRefresh={setRefresh}/>
        <RunningAmountView />
        <DataTable search={searchValue} refresh={refresh} setRefresh={setRefresh}/>
      </div>
    );
  }
  
  export default Home;