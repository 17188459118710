import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
    props,
    ref
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const CustomModal = (props) => {
    const { open, title, actions, handleClose, width } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth={width?width:"md"}
            fullWidth={true}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogTitle id="scroll-dialog-title">
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "600", color: theme.palette.primary.main }}>{title}</Typography>
                    <IconButton>
                        <CloseIcon onClick={handleClose} />
                    </IconButton>
                </Stack></DialogTitle>
            {props.children ? <DialogContent dividers={true}>{props.children}</DialogContent> : null}
            <DialogActions>{actions}</DialogActions>
        </Dialog>
    );
};

export default CustomModal;
