// components/CreateCustomer.js
import React, { useState } from 'react';
import { createCustomer } from '../../service/api.service';

const CreateCustomer = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    pan: '',
    aadhar: '',
    nominee: '',
    relativeName: '',
    address: '',
    phoneNumber: '',
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateCustomer = async () => {
    try {
      await createCustomer(formData);
      // Optionally, you can navigate to another page or show a success message
    } catch (error) {
      console.error('Error creating customer:', error);
      // Handle the error, show a message, etc.
    }
  };

  return (
    <div>
      <h2>Create Customer</h2>
      <form>
        <label>
          First Name:
          <input type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} />
        </label>
        <label>
          Last Name:
          <input type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} />
        </label>
        <label>
          Email:
          <input type="email" name="email" value={formData.email} onChange={handleInputChange} />
        </label>
        <label>
          Phone:
          <input type="number" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} />
        </label>
        <button type="button" onClick={handleCreateCustomer}>Create Customer</button>
      </form>
    </div>
  );
};

export default CreateCustomer;
