import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { addService, getAllService } from "../../../service/api.service";
import { Card, Container } from "@mui/material";
import { useEffect } from "react";
import ServicesPage from "./ServicesPage";

const serviesList = [{ title: "Loan" }, { title: "Saving" }];

const frequencyList = [
  { title: "Daily" },
  { title: "Weekly" },
  { title: "Monthly" },
  { title: "Yearly" },
];

const style = {
  bgcolor: "background.paper",
  boxShadow: 5,
  p: 4,
  mb: 8,
};

export default function Services() {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs(null));
  const [tvalue, setTValue] = React.useState<Dayjs | null>(dayjs());

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    addService({
      name: data.get("serviceName"),
      type: data.get("serviceType"),
      amount: data.get("amount"),
      collectionFrequency: data.get("frequency"),
      isActive: data.get("isActive"),
      loan: {
        loanAmount: data.get("loanAmount"),
        interestRate: data.get("interestRate"),
        duration: data.get("duration"),
      },
      saving: {
        targetAmount: data.get("targetAmount"),
      },
      description: data.get("description"),
    })
      .then((res) => console.log("service type res", res))
      .catch((err) => console.log("Error while adding service type", err));
  };

  return (
    <React.Fragment>
      <Container
        maxWidth={"md"}
        sx={{ justifyContent: "center", paddingLeft: "18px" }}
      >
        <Typography
          sx={{ flex: "1 1 100%", textAlign: "center" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Services
        </Typography>
        <Box component={"form"} onSubmit={handleSubmit} sx={style}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                // multiple
                id="size-small-outlined-multi"
                size="small"
                options={serviesList}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Services Type"
                    name="serviceType"
                    placeholder="add..."
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                required
                id="serviceName"
                name="serviceName"
                label="Service Name"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                // multiple
                id="size-small-outlined-multi"
                size="small"
                options={frequencyList}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Frequency"
                    name="frequency"
                    placeholder="add..."
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                size="small"
                // required
                id="amount"
                name="amount"
                label="Amount"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                size="small"
                // required
                id="interestRate"
                name="interestRate"
                label="Interest Rate (%)"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                size="small"
                // required
                id="duration"
                name="duration"
                label="Duration (in days)"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                // required
                id="description"
                name="description"
                label="Description"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid
            style={{
              marginTop: "2%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            xs={12}
            sm={6}
          >
            <Button
              style={{ marginRight: "16px" }}
              fullWidth
              variant="outlined"
              endIcon={<CancelIcon />}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              endIcon={<SendIcon />}
              type="submit"
            >
              Submit
            </Button>
          </Grid>
        </Box>
        <Box sx={style}>
          <ServicesPage />
        </Box>
      </Container>
    </React.Fragment>
  );
}
