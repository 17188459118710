import { useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";
const InfiniteList = (props) => {
    const { data, rowHeight = 60, height, width, loadMoreItems, ...listProps } = props;
    const [itemCount, setItemCount] = useState(data?.length ?? 0);
    useEffect(() => {
        setItemCount(data?.length);
    }, [data])

    return (
        <List
            {...listProps}
            height={height}
            itemCount={itemCount}
            itemSize={rowHeight}
            alignItems="center"
            width={width ? width : "100%"}
            // overscanCount={1}
            onItemsRendered={loadMoreItems}
        >
            {props.children}
        </List>
    )
}

export default InfiniteList;